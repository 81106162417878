import React from "react";
import Form from "./Form";

const LandingPage = () => {
  return (
    <div className="flex relative w-full h-screen">
      <img
        className="absolute top-0 left-0 w-full h-full object-fill"
        src="/LandingPage/background.jpg"
        alt="background"
      />
      <div className="relative flex justify-between z-10 bg-white w-[1200px] h-[700px] pt-8 mx-auto my-auto 
      rounded-[30px] overflow-hidden shadow-custom-strong">
        <div className="w-[49.4%]">
          <img
            className="w-full h-full object-cover rounded-tr-[70px]"
            src="LandingPage/bgleft.jpg"
            alt="image1"
          />
        </div>
        <div className="w-[49.4%]">
          <img
            className="w-full h-full object-cover rounded-tl-[70px]"
            src="LandingPage/bgright.jpg"
            alt="image2"
          />
        </div>
 
        <div className="absolute w-[750px] h-[580px] bottom-0 left-1/2 transform -translate-x-1/2
         bg-white/10 backdrop-blur-[40px] rounded-t-[50px] overflow-hidden shadow-custom-strong">
            <Form/>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
