import React, { useEffect, useState } from "react";
import SubHeader from "../customComponents/SubHeader";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import api from "../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/alertSlice";

const MessageConfiguration = () => {

  const [formData, setFormData] = useState({
    instanceid : '',
    token : '',
    panel : '',
    phone : '',
  })
  const [loading, setLoading] = useState(false)
  console.log(formData)

  const dispatch = useDispatch();

  const handleChange = (name, value)=>{
    setFormData((prev)=>({
      ...prev,
      [name] : value
    }))
  }

  const getPanelConfig = async ()=>{
    try {
      setLoading(true)
      const response = await api.get('/social/get-panel-config')
      if (response.data.success) {
        setLoading(false)
        setFormData(response.data.data)
      } else {
        dispatch(showAlert({type:'warning', message:'Failed to get panel configuration.'}))
        setLoading(false)
      }
    } catch (error) {
      console.log('Error in getting panel config', error)
      dispatch(showAlert({type:'error', message:'Error in getting panel configuration.'}))
      setLoading(false)
    }
  }

  const savePanelInfo = async ()=>{
    try {
      setLoading(true)
      const response = await api.post('/social/panel-setup', {formData:formData})
      if (response.data.success) {
        dispatch(showAlert({type:'success', message:'Panel configuration updated successfully.'}))
        setLoading(false)
      } else {
        dispatch(showAlert({type:'warning', message:'Failed to update panel configuration.'}))
        setLoading(false)
      }
    } catch (error) {
      console.log('Error in updatig panel info', error)
      dispatch(showAlert({type:'error', message:'Error in updating panel configuration.'}))
      setLoading(false)
    }
  }

  useEffect(()=>{
   getPanelConfig();
  },[])

  return (
    <div>
      <SubHeader text={"Message Configuration"} />

      <div className="overflow-hidden lg:mb-8 lg:mt-6 lg:max-w-[45rem] lg:mx-auto bg-white shadow-custom-dark rounded-lg">
        <div className="bg-primary text-white font-mont font-semibold py-2 px-4">
          Update Message Configuration
        </div>

        <div className="space-y-8 px-6">
          <div className="border-b border-gray-900/10 pb-8">
            <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm/6 text-gray-900 font-mont font-semibold"
                >
                  Instance Id
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={formData?.instanceid}
                    onChange={(e)=>{handleChange('instanceid', e.target.value)}}
                    autoComplete="off"
                    className="block w-full font-mont rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block font-mont text-sm/6 text-gray-900 font-semibold"
                >
                  Token
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={formData?.token}
                    onChange={(e)=>{handleChange('token', e.target.value)}}
                    autoComplete="off"
                    className="block w-full font-mont rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  className="block font-mont text-sm/6 font-semibold text-gray-900"
                >
                  Select Panel
                </label>
                <div className="mt-2 grid grid-cols-1">
                  <select
                    value={formData.panel.trim()}
                    onChange={(e)=>{handleChange('panel', e.target.value)}}
                    autoComplete="country-name"
                    className="col-start-1 row-start-1 w-full font-mont appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  >
                    <option value={1}>Panel 1</option>
                    <option value={2}>Panel 2</option>
                    <option value={3}>Panel 3</option>
                  </select>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm/6 text-gray-900 font-mont font-semibold"
                >
                  Phone
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={formData?.phone.trim()}
                    onChange={(e)=>{handleChange('phone', e.target.value)}}
                    autoComplete="off"
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base font-mont text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 mr-6 flex items-center justify-end space-x-2">
          <button
            onClick={savePanelInfo}
            className="py-2 w-28 mr-2 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70"
            disabled={loading}
          >
            SAVE
          </button>
          <button
            onClick={getPanelConfig}
            className="py-2 w-28 border border-fifth  text-fifth text-sm font-mont font-semibold rounded-[5px] hover:bg-white"
            disabled={loading}
          >
            REVERT
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageConfiguration;
