import React, { useEffect, useState } from 'react'
import SubHeader from '../customComponents/SubHeader'
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/alertSlice';
import api from '../../utils/axiosInstance';
import CustomerSearchBox from '../customComponents/CustomerSearchBox'
import moment from 'moment';
import Dropbox from '../customComponents/DropBox';
import SchemeSummaryHistory from './Scheme/SchemeSummaryHistory';
import axios from 'axios';
import { address } from '../../constants/address';

const filter = [
  {id:0, type:'All'},
  {id:1, type:'Active'},
  {id:2, type:'Inactive'},
  {id:3, type:'Matured'},
]

const SchemeSummary = () => {

  const [formData, setFormData] = useState({
    mobileno : '',
    datefrom: moment(new Date()).format('YYYY-MM-DD'),
    dateto: moment(new Date()).format('YYYY-MM-DD'),
  })
  console.log(formData)
  const [array, setArray] = useState([])
  const [selected, setSelected] = useState(filter[0].id)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();

  const handleInputChange = (name, value)=>{
      setFormData((prev)=>({
        ...prev,
        [name] : moment(value).format('YYYY-MM-DD')
      }))
  }

  const getFyear = async()=>{
    try {
      const response = await axios.get(`${address}/customers/get-fyear`)
      if (response.data.success) {
        setFormData({
          ...formData,
          datefrom : moment(response.data?.data?.sfdate).format('YYYY-MM-DD')
        })
      } else {
        console.log('Error in fetching financial year.')
      }
    } catch (error) {
      console.log("Error in getting financial year error : " + error);
    }
  }

  useEffect(()=>{
    getFyear();
  },[])
  

  const handleGenerateSummary = async ()=>{
    try {
      setLoading(true)
      const response = await api.post('scheme/generate-summary', {selected:selected?.type, formData:formData})
        if (response.data?.success) {
        setArray(response.data?.data)
        setLoading(false)
      } else {
        setArray([])
        dispatch(showAlert({type:'warning', message:'No records match the specified filters.'}))
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('Error in generating summary.', error)
      dispatch(showAlert({type:'error', message:'Error in generating summary.'}))
    }
  }

  const handleGenerate = async() =>{
  handleGenerateSummary();
  } 

  return (
    <>
      <SubHeader text={"Scheme Summary"} />
      <div className="w-[1100px] mx-auto">
        <div className="relative w-full h-[620px] rounded-[10px] mt-8 mb-2 shadow-custom-dark">
          <div className="flex">
            <div className="w-[40%] flex justify-center">
              <img
                className="w-[340px] object-cover"
                src="/forms/formbg2.jpg"
                alt=""
              />
            </div>

            <div className="w-[60%] py-6 px-6">
              <div>
                <div className="font-mont font-semibold border-b pb-2">
                  Scheme Summary
                </div>
                <div className="flex space-x-12 items-end">
                  <div className="w-[350px] mt-6 bg-white">
                    <CustomerSearchBox
                      value={formData.mobileno} // Pass current mobileno
                      getValue={(value) => {
                        setFormData({
                          ...formData,
                          mobileno: value.MOBILENO ? value.MOBILENO : '', // Check for null or undefined
                        });
                      }}
                      searchLabel="Search customer mobile no"
                    />
                  </div>
                  <div className="w-52">
                    <Dropbox array={filter} setOption={setSelected} />
                  </div>
                </div>

                <div className="mt-6 flex items-end space-x-12">
                  <TextField
                    sx={{ width: 150 }}
                    value={formData.datefrom}
                    label="From"
                    variant="standard"
                    onChange={(e) => {
                      handleInputChange("datefrom", e.target.value);
                    }}
                    type="date"
                  />
                  <TextField
                    sx={{ width: 150 }}
                    value={formData.dateto}
                    label="To"
                    variant="standard"
                    onChange={(e) => {
                      handleInputChange("dateto", e.target.value);
                    }}
                    type="date"
                  />
                  <button
                    onClick={handleGenerate}
                    disabled={loading}
                    className="py-2 px-8 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
                  >
                    Generate Summary
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-7">
            <SchemeSummaryHistory data={array} refresh={handleGenerateSummary}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchemeSummary
