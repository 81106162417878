import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../utils/axiosInstance";

const FreeSolo = ({ value, getValue, searchLabel }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(""); // Local state for input value
  const [selectedValue, setSelectedValue] = useState(value || null); // Local state for selected object
  const searchBoxRef = useRef();

  const getCustomerList = async (inputValue) => {
    try {
      const response = await api.get(
        `/customers/get-all-customers?search=${inputValue}`
      );
      if (response.status === 200) {
        setOptions(response.data);
      }
    } catch (error) {
      console.error("Error while loading customers", error);
    }
  };

  const handleOnChange = (newValue) => {
    setSelectedValue(newValue); // Store the selected object
    setInputValue(newValue?.CUSTNAME || ""); // Update the input field with the selected name
    getValue(newValue); // Pass the selected object to the parent
  };

  const debounceTimeout = useRef(null);
  const handleSearch = (newInputValue) => {
    setInputValue(newInputValue); // Update the inputValue for typing feedback
    if (newInputValue) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        getCustomerList(newInputValue);
      }, 1000);
    } else {
      setOptions([]); // Clear options if input is empty
    }
  };

  useEffect(() => {
    searchBoxRef.current.focus();
  }, []);

  return (
    <Autocomplete
      size="small"
      freeSolo
      autoHighlight
      value={selectedValue} // Bind the selected object to the `value`
      inputValue={inputValue} // Bind the displayed text to `inputValue`
      onChange={(event, newValue) => handleOnChange(newValue ? newValue : '')}
      onInputChange={(event, newInputValue) => handleSearch(newInputValue ? newInputValue : '')}
      options={options}
      getOptionLabel={(option) =>
        option && typeof option === "object"
          ? `${option.CUSTNAME || ""} (+91) ${option.MOBILENO || ""}`
          : ""
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={searchLabel}
          inputRef={searchBoxRef}
        />
      )}
    />
  );
};

export default FreeSolo;
