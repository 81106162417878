import moment from 'moment'
import React, { useState } from 'react'
import api from '../../../utils/axiosInstance'
import SummaryModalBox from './SummaryModalBox'
import { TrashIcon } from '@heroicons/react/24/solid'
import ModalDelete from './ModalDelete'

const SchemeSummaryHistory = ({data, refresh}) => {

    const [showSchemeStatement, setShowSchemeStatement] = useState(false)
    const [array, setArray] = useState([])
    const [modalBoxStatus, setModalBoxStatus] = useState(false)
    const [schemeId, setSchemeId] = useState(null)
    const total = data.reduce((acc, item) => acc + item.amount, 0);

    const handleGenerateStatement = async (id)=>{
      try {
        const response = await api.post('scheme/generate-statement', {schemeid:id})
        if (response.data?.success) {
        setArray(response.data?.data)
        } else {
        setArray([])  
        }
      } catch (error) {
        console.log('Error in generating statement.', error)
      }
    }

    const handleSchemeClick = (row)=>{
      if (row.matyn !== 'n') { 
        setShowSchemeStatement(true)
        handleGenerateStatement(row?.schemeid)
      }
    }

  return (
    <div className="border h-full rounded-[15px] shadow-custom-light overflow-hidden">
      {<SummaryModalBox data={array} state={showSchemeStatement} onClose={(value)=>{setShowSchemeStatement(value)}}/>}
       <ModalDelete status={modalBoxStatus} setStatus={(value)=>{setModalBoxStatus(value)}} schemeId={schemeId} refresh={refresh}/>

      <div
        className={`bg-sixth flex text-white font-mont font-semibold text-sm`}
      >
        <div className="w-[15%] py-2 px-3">Scheme ID</div>
        <div className="w-[25%] py-2 px-3">Name</div>
        <div className="w-[15%] py-2 px-3">Mobile No</div>
        <div className="w-[15%] py-2 px-3">Start Date</div>
        <div className="w-[15%] py-2 px-3">Amount</div>
        <div className="w-[15%] py-2 px-3">Status</div>
      </div>
      <div className="h-[280px] overflow-y-auto font-mont font-bold text-sm">
        {data.length > 0 ? (
          data.map((row, i) => (
            <div
              onClick={()=>{handleSchemeClick(row)}}
              key={i}
              className={`flex font-semibold text-sm hover:bg-sixth/30 hover:cursor-pointer ${
                i % 2 === 0 ? "bg-sixth/10" : "bg-sixth/5"
              }`}
            > 
              <div className="w-[15%] h-[35px] flex items-center px-3">
               {row?.schemeid}
              </div>
              <div className="w-[25%] h-[35px] flex items-center px-3">
               {row?.custname}
              </div>
              <div className="w-[15%] h-[35px] flex items-center px-3">
                {row?.mobileno}
              </div>
              <div className="w-[15%] h-[35px] flex items-center px-3">
                {moment(row?.startdt).format('DD-MM-YYYY')}
              </div>
              <div className="w-[15%] h-[35px] flex items-center justify-end px-3">
              ₹ {row?.amount}
              </div>
              <div className="w-[15%] h-[35px] flex items-center px-3">
              {
                (row?.matyn === 'n' || row?.matyn === '' || !row?.matyn) && 
                <>
                 <button 
                 onClick={()=>{setModalBoxStatus(true); setSchemeId(row?.schemeid)}}
                 className='flex items-center space-x-1 border rounded-lg px-2 py-[2px] border-red-500 bg-white'>
                 <div >Inactive</div>
                 <TrashIcon className='w-4 h-4 text-red-500'/>
                 </button>
                </>
                }
                {row?.matyn === 'a' && <button className='border rounded-lg px-2 py-[2px] border-green-500 bg-white'>Active</button>}
                {row?.matyn === 'm' && <button className='border rounded-lg px-2 py-[2px] border-third bg-white'>Completed</button>}
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <img className="w-64 mx-auto" src="/table/tablebg.jpg" alt="" />
            <div className="text-center font-mont tedt-sm font-semibold text-gray-400">
                No results yet.
            </div>
          </div>
        )}
      </div>
      <div className='h-[50px] flex items-center border-t border-gray-300'>
        <div className='w-[40%] px-3'></div>
        <div className='w-[15%] px-3'>              
            <button
                //onClick={reactToPrintFn}
                className="py-1 w-32 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
              >
                Print
            </button>
        </div>
        <div className='w-[15%] px-3'>              
            <button
                //onClick={reactToPrintFn}
                className="py-1 w-32 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
              >
                Download CSV
            </button>
        </div>
        <div className='w-[15%] px-3 flex justify-between text-sm font-mont font-bold'>    
          <div>Total - </div>          
          <div>₹ {total}</div>          
        </div>
      </div>
    </div>
  )
}

export default SchemeSummaryHistory
