import React, { useEffect, useState } from "react";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import api from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setActiveUser } from "../../redux/userSlice";

const SubHeader = ({ text }) => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store?.user?.activeUser);

  const checkAuthentication = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const loggedInUser = await api.get(`/user/current-user`);
        if (!loggedInUser?.data?.info?.success) {
          dispatch(setActiveUser({ activeUser: loggedInUser?.data?.user?.USERNAME }));
          navigate("/");
          localStorage.removeItem("accessToken");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
      localStorage.removeItem("accessToken");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  useEffect(() => {
    // Clear token and navigate to "/" on page reload
    const handlePageReload = () => {
      localStorage.removeItem("accessToken");
      navigate("/"); // Navigate to "/"
    };

    window.addEventListener("beforeunload", handlePageReload);

    // Check authentication on component load
    checkAuthentication();

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, [navigate]); // Include navigate as dependency

  return (
    <div className="flex w-full items-center justify-between mb-4 py-2 pr-4 bg-primary shadow-custom-medium">
      <div className="flex space-x-1 font-semibold font-mont text-fourth">
        <div>
          <ViewHeadlineOutlinedIcon />
        </div>
        <p>{text}</p>
      </div>
      <div className="relative">
        <button
          onFocus={() => setMenu(true)}
          onBlur={() => setMenu(false)}
          className="flex pl-4 space-x-1 items-center py-2 text-fourth font-bold font-mont text-sm hover:cursor-pointer"
        >
          <AccountCircleOutlinedIcon />
          <p>{user}</p>
          <ArrowDropDownIcon />
        </button>
        <div
          className={`${
            menu ? "absolute flex" : "hidden"
          } bg-white border shadow-custom-medium rounded-sm w-full text-primary`}
        >
          <button className="w-full my-1 py-1 hover:bg-fourth" onMouseDown={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
