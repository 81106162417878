import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    status: false,
    type: '',
    message: '',
  },
  reducers: {
    showAlert: (state, action) => {
      state.status = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    clearAlert: (state) => {
      state.status = false;
      state.message = '';
      state.type = '';
    }
  }
});

export const { showAlert, clearAlert } = alertSlice.actions;
export default alertSlice.reducer;
