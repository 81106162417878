import React, { useEffect, useRef, useState } from "react";
import SubHeader from "../customComponents/SubHeader";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import api from "../../utils/axiosInstance";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/alertSlice";
import { CSVLink } from "react-csv";

const CustomerProfile = () => {
  
  const [formData, setFormData] = useState({
    custid:'',
    mobileno : '',
    custname: '',
    email: '',
    address: '',
    city:'',
    pincode:'',
    state:'',
    dob: '',
    doa: '',
    aadhaar: '',
    pan:''
  }) 
  console.log(formData)
  const [customerList, setCustomerList] = useState([])
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]); // option array coming from searching
  const [loading, setLoading] = useState(false);

  const searchBoxRef = useRef();
  const csvLinkRef = useRef();
  const dispatch = useDispatch()
  const debounceTimeout = useRef(null); // Ref to store the timeout

  const getCustomerList = async (value) => {
    try {
      const response = await api.get(
        `/customers/get-all-customers?search=${value}`
      );
      if (response.status === 200) {
        const list = response.data;
        //console.log(list);
        setOptions(list);
      }
    } catch (error) {
      console.log("Error while loading customers " + error);
    }
  };

  const getCustomerData = async (number) => {
   try {
      setLoading(true)
      handleClearForm() // clearing form before updating all values in form after number selection.
      const response = await api.post('/customers/get-customer-data', {mobileno : number});
      if (response.data?.success) {
        const {custid, mobileno, custname, email, address, city, pincode, state, dob, doa, aadhaar, pan} = response.data?.data
        console.log(response.data?.data)
        setFormData({
          ...formData,
          custid: custid,
          mobileno : mobileno,
          custname : custname,
          email : email,
          address : address,
          city : city,
          pincode : pincode,
          state : state,
          dob: dob && dob !== '1900-01-01T00:00:00.000Z' ? moment(dob).format('YYYY-MM-DD') : '', // Replace default date with ''
          doa: doa && doa !== '1900-01-01T00:00:00.000Z' ? moment(doa).format('YYYY-MM-DD') : '', // Replace default date with ''
          aadhaar : aadhaar,
          pan : pan
        })
        setLoading(false)
      } else {
        console.log(response.data?.message)
        setLoading(false)
      }
   } catch (error) {
    console.log(error)
    setLoading(false)
   }
  }

  const downloadCustomerList = async () => {
    try {
      setLoading(true);
      const response = await api.get('/customers/download-customer-data');
      console.log(response.data.data);
      
      if (response.data.success) {
        setCustomerList(response.data.data);

        // Wait for state update before triggering click
        setTimeout(() => {
          if (csvLinkRef.current && csvLinkRef.current.link) {
            csvLinkRef.current.link.click();
          } else {
            console.error('CSVLink ref is not available.');
          }
        }, 50);

        setLoading(false);
      } else {
        setLoading(false);
        dispatch(showAlert({ type: 'error', message: 'No data found to export.' }));
      }
    } catch (error) {
      setLoading(false);
      console.error('Error in exporting the customer list', error);
      dispatch(showAlert({ type: 'error', message: 'Error in exporting customer data.' }));
    }
  };
  
  const handleOnChange = async (value)=>{  // Get info of cutomer for selected option from list
   getCustomerData(value?.MOBILENO)
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value && value !== "") {
      setFormData((pre) => ({
        ...pre,
        mobileno: value,
      }));
  
      // Debouncing logic
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
  
      debounceTimeout.current = setTimeout(() => {
        getCustomerList(value);
      }, 1000); // 1-second delay
    }
  };

  const handleInputChange = (name, value)=>{
    setFormData((pre)=>({
      ...pre,
      [name]:value === null ? '' : value,
    }))
  }

  const handleSave = async ()=>{
    try {
      setLoading(true)
      const response =  await api.post('/customers/update-customer-data',{formData})
      if (response.data?.success) {
        console.log(response.data?.message)
        dispatch(showAlert({type:'success',message:response.data?.message}))
        setLoading(false)
        handleCancel()
      } else {
        console.log(response.data?.message)
        dispatch(showAlert({type:'warning',message:response.data?.message}))
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      dispatch(showAlert({type:'error',message:'Error in saving.'}))
      setLoading(false)
    }
  }

  const handleOnSaveClick = async ()=>{
    if (formData?.mobileno !== '' && formData.mobileno.length === 10) {
      handleSave();
    } else {
      if (formData.mobileno === '' || inputValue === '') {
        dispatch(showAlert({type:'warning', message:'Please enter 10 digits mobile no before proceeding.'}))
      } else if(formData.mobileno.length !== 10) {
        dispatch(showAlert({type:'warning', message:'Mobile number should be of 10 digits.'}))
      } else if (formData.custname === '') {
        dispatch(showAlert({type:'warning', message:'Please enter name before proceeding.'}))
      }
    }
  }

  const handleClearForm = ()=>{
    setFormData({
      custid: '',
      mobileno: '',
      custname: '',
      email: '',
      address: '',
      city: '',
      pincode: '',
      state: '',
      dob: '',
      doa: '',
      aadhaar: '',
      pan: '',
    });
  }

  const handleCancel = () => {
    setFormData({
      custid: '',
      mobileno: '',
      custname: '',
      email: '',
      address: '',
      city: '',
      pincode: '',
      state: '',
      dob: '',
      doa: '',
      aadhaar: '',
      pan: '',
    });
    setSelectedValue(null); // Clear selected value in Autocomplete
    setInputValue(''); // Clear input text
  };

  useEffect(()=>{
    searchBoxRef.current.focus();
  },[])

  return (
    <div>
      <SubHeader text={"Customer Profile"} />
      <div className="w-full lg:w-[1100px] mx-auto">
        <div className="w-full mx-auto rounded-[10px] mt-12  shadow-custom-dark">
          <div className="w-full flex">
            <div className="hidden lg:flex lg:w-[35%] items-center bg-white rounded-l-[10px]">
              <img
                className="w-full mx-auto rounded-l-[10px]"
                src="/forms/customer_profile.png"
                alt=""
              />
            </div>
            <div className="w-full lg:w-[65%] lg:py-8 lg:px-10">
              <div className="lg:flex justify-between items-end border-b pb-2">
                <div className="font-mont font-semibold">
                  Create / Update customer profile
                </div>
              </div>

              <div className="w-full">
                <div className="w-full">
                  <div className="mt-7">
                    <Autocomplete
                      size="small"
                      freeSolo
                      autoHighlight
                      value={selectedValue}
                      inputValue={inputValue} // Bind the inputValue to control the input text
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue); // Update the input text
                        handleSearch({ target: { value: newInputValue } });
                      }}
                      onChange={(event, newValue) => {
                        setSelectedValue(newValue); // Update selected object
                        newValue && handleOnChange(newValue); // Trigger onChange action
                      }}
                      options={options} // Pass the original customer objects
                      getOptionLabel={(option) =>
                        `${option.CUSTNAME ? option.CUSTNAME : ''}   (+91) ${option.MOBILENO}`
                      } // Control how options are displayed
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          autoComplete="newpassword"
                          label='Enter mob no.'
                          inputRef={searchBoxRef} // Use inputRef to focus the input field
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.custname}
                      label="Name"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('custname',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.email}
                      label="Email"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('email',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.address}
                      label="Address"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('address',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.city}
                      label="City"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('city',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.pincode}
                      label="Pincode"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('pincode',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.state}
                      label="State"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('state',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-7 w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker style = {{height : '10px'}}
                        className="w-full"
                        label="Date of Birth"
                        value={formData.dob ? dayjs(formData.dob) : null} // Convert to dayjs object
                        onChange={(newValue) => handleInputChange('dob', newValue ? newValue.format('YYYY-MM-DD') : null)} // Convert back to string
                        format="DD/MM/YYYY"
                        slotProps={{
                          actionBar: {
                            actions: ['clear'], // Enables the clear action button
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="mt-7 w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        label="Anniversary Date"
                        className="w-full"
                        value={formData.doa ? dayjs(formData.doa) : null} // Convert to dayjs object
                        onChange={(newValue) => handleInputChange('doa', newValue ? newValue.format('YYYY-MM-DD') : null)} // Convert back to string
                        format="DD/MM/YYYY"
                        slotProps={{
                          actionBar: {
                            actions: ['clear'], // Enables the clear action button
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.aadhaar}
                      label="Aadhaar"
                      variant="standard"
                      inputProps={{ maxLength: 16 }} // Set max length here
                      onChange={(e)=>{handleInputChange('aadhaar',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.pan}
                      label="PAN"
                      variant="standard"
                      inputProps={{ maxLength: 10 }} // Set max length here
                      onChange={(e)=>{handleInputChange('pan',e.target.value)}}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-6">
                <div>
                <button
                  onClick={handleOnSaveClick}
                  className="py-2 w-28 mr-2 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70"
                  disabled={loading}
                >
                  SAVE
                </button>
                <button
                  onClick={handleCancel}
                  className="py-2 w-28 border border-fifth  text-fifth text-sm font-mont font-semibold rounded-[5px] hover:bg-white"
                  disabled={loading}
                >
                  CANCEL
                </button>
                </div>
                <div>
                <button
                  onClick={downloadCustomerList}
                  className="py-2 w-28 border border-primary bg-second  text-primary text-sm font-mont font-semibold rounded-[5px] hover:bg-second/70"
                  disabled={loading}
                >
                  Export All
                </button>
                {customerList.length > 0 ? <CSVLink
                    className="hidden"
                    ref={csvLinkRef}
                    data={customerList.length > 0 && customerList.map((item) => {
                      return {
                        ...item
                      };
                    })}
                    filename="customer_list.csv"
                  >
                    <p className="text-primary text-sm font-mont font-semibold">Export All</p>
                  </CSVLink> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
