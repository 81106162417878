import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name : 'userSlice',
    initialState : {
        activeUser : ''
    } ,
    reducers:{
        setActiveUser : (state, action)=>{
          state.activeUser = action.payload;
        }
    }
})

export const {setActiveUser} = userSlice.actions;
export default userSlice.reducer;