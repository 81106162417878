import React from "react";
import { Route, Routes } from "react-router-dom";
import MyApp from "./MyApp";
import User from './User';
import Dashboard from './Dashboard';
import SideMenu from "../customComponents/SideMenu";
import LoyaltyPoints from "./LoyaltyPoints";
import Statement from './Statement';
import CustomerProfile from './CustomerProfile';
import MessageConfiguration from './MessageConfiguration'
import SchemeGeneration from './SchemeGeneration';
import SchemeStatement from './SchemeStatement';
import AlertBox from "../customComponents/AlertBox";
import Footer from "../customComponents/Footer";
import SchemeSummary from "./SchemeSummary";

const Home = () => {
  return (
    <div className="flex h-screen bg-white">
      <div className="min-w-[250px] h-full">
        <SideMenu />
      </div>
      <div className="relative w-full overflow-auto">
        <AlertBox/>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="mobile-app" element={<MyApp />} />
          <Route path="customer-profile" element={<CustomerProfile />} />
          <Route path="loyalty-points" element={<LoyaltyPoints/>} />
          <Route path="scheme-generation" element={<SchemeGeneration />} />
          <Route path="message-configuration" element={<MessageConfiguration />} />
          <Route path="user" element={<User />} />
          <Route path="statement" element={<Statement/>} />
          <Route path="scheme-statement" element={<SchemeStatement/>} />
          <Route path="scheme-summary" element={<SchemeSummary/>} />
        </Routes>
    <Footer/>
      </div>
    </div>
  );
};

export default Home;
