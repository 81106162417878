import {configureStore} from '@reduxjs/toolkit';
import imageReducer from './imageSlice'
import userReducer from './userSlice'
import alertReducer from './alertSlice'

const appStore = configureStore({
      reducer:{
        images : imageReducer,
        user : userReducer,
        alert : alertReducer,
      }
})

export default appStore;