import { createSlice } from "@reduxjs/toolkit";

const imageSlice = createSlice({
    name : 'imageSlice',
    initialState : {
        images : null,
    } ,
    reducers:{
       updateImages : (state, action)=>{
        state.images = action.payload;
       }
    }
})

export const {updateImages} = imageSlice.actions;
export default imageSlice.reducer;