import React from 'react'

const Footer = () => {
  return (
    <div className='fixed flex space-x-2 justify-center bottom-0 left-0 w-full bg-primary text-fourth font-mont text-sm'>
      <p>Powered by Solution Point.</p>
      <p>+91 9897090913</p>
    </div>
  )
}

export default Footer
