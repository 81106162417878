import axios from "axios";
import { address } from "../constants/address";

const api = axios.create({
  baseURL: `${address}`, // Replace with your backend URL
  timeout: 5000, // Timeout after 5 seconds

  //withCredentials: true, // Send cookies with requests, if needed for CORS
});

api.interceptors.request.use(
  (request) => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);
export default api;
