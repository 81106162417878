import React, { useEffect, useState } from "react";
import SubHeader from "../customComponents/SubHeader";
import CustomerSearchBox from "../customComponents/CustomerSearchBox";
import { TextField } from "@mui/material";
import StatementTable from "../customComponents/StatementTable";
import api from "../../utils/axiosInstance";
import axios from "axios";
import { address } from "../../constants/address";

const Statement = () => {
  const [formData, setFormData] = useState({
    CUSTNAME: "",
    MOBILENO: "",
    DFROM: "",
    DTO: new Date().toISOString().split("T")[0],
  }); // Setting form data received from search box

  const [statementData, setStatementData] = useState([]); // this will hold statement recordset
  //console.log(statementData)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleGenerateStatement = async () => {
    try {
      const response = await api.post(
        `/customers/generate-statement`,
        {
          MOBILENO: formData.MOBILENO,
          DFROM: formData.DFROM,
          DTO: formData.DTO,
        }
      );
      if (response.status === 200) {
        setStatementData(response.data);
      }
    } catch (error) {
      console.log("Error in getting statement error : " + error);
    }
  };

  const getFyear = async()=>{
    try {
      const response = await axios.get(`${address}/customers/get-fyear`)
      if (response.data.success) {
        setFormData({
          ...formData,
          DFROM : new Date(response.data?.data?.sfdate).toISOString().split("T")[0]
        })
      } else {
        console.log('Error in fetching financial year.')
      }
    } catch (error) {
      console.log("Error in getting financial year error : " + error);
    }
  }

  useEffect(()=>{
    getFyear();
  },[])

  return (
    <>
      <SubHeader text={"Points Statement"} />
    <div className="w-[1100px] mx-auto">
      <div className="relative w-full h-[620px] rounded-[10px] mt-8 mb-2 shadow-custom-dark">
        <div className="flex">
          <div className="w-[40%] flex justify-center">
            <img
              className="w-[340px] object-cover"
              src="/forms/formbg2.jpg"
              alt=""
            />
          </div>

          <div className="w-[60%] py-6 px-6">
            <div>
              <div className="font-mont font-semibold border-b pb-2">
                Generate Statement
              </div>
              <div className="flex space-x-12 items-end">
                <div className="w-[350px] mt-6 bg-white">
                  <CustomerSearchBox
                    searchLabel={'Search customer mobile no'}
                    getValue={(value) => {
                      setFormData({
                        ...formData,
                        MOBILENO: value.MOBILENO,
                        CUSTNAME: value.CUSTNAME ? value.CUSTNAME : '',
                      });
                    }}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ width: 210 }}
                    value={formData.CUSTNAME}
                    label="Name"
                    variant="standard"
                    slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
                  />
                </div>
              </div>

              <div className="mt-6 flex items-end space-x-12">
                <TextField
                  name="DFROM"
                  sx={{ width: 150 }}
                  value={formData.DFROM}
                  label="Date from"
                  variant="standard"
                  onChange={handleInputChange}
                  type="date"
                />
                <TextField
                  name="DTO"
                  sx={{ width: 150 }}
                  value={formData.DTO}
                  label="Date to"
                  variant="standard"
                  onChange={handleInputChange}
                  type="date"
                />
                <button
                  onClick={handleGenerateStatement}
                  disabled={formData.MOBILENO === ""}
                  className="py-2 px-8 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
                >
                  Generate Statement
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-7 h-[370px]">
          <StatementTable data={statementData} dfrom={formData.DFROM} dto={formData.DTO} generateStatement={handleGenerateStatement}/>
        </div>

      </div>
    </div>
    </>
  );
};

export default Statement;
