import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDispatch } from "react-redux";
import { } from "../../redux/imageSlice";
import { showAlert } from "../../redux/alertSlice";
import api from "../../utils/axiosInstance";

const ImageShow = ({ src, id, path, type, getImages }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const response = await api.post('/file/delete',{id : id, path : path})
      if (response.data.success) {
        await getImages(type);
        dispatch(showAlert({type:'success', message:'Image deleted.'}))
      } else {
        dispatch(showAlert({type:'error', message:'Error in deleting image.'}))
      }
    } catch (error) {
      dispatch(showAlert({type:'error', message:'Error in deleting image.'}))
      console.log('Error in deleting image '+error)
    }
  };

  return (
    <div className="relative rounded-[10px]">
      <div
        onClick={handleDelete}
        className="absolute hover:cursor-pointer text-fifth hover:text-fifth/80 bg-white rounded-full -top-3 -right-3"
      >
        <RemoveCircleIcon />
      </div>
      <img
        className="min-w-56 h-32 shadow-custom-light rounded-[10px] object-cover"
        src={src}
        alt="Not found"
      />
    </div>
  );
};

export default ImageShow;
