import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import SchemeStatementHistory from './SchemeStatementHistory'

const SummaryModalBox = ({data, state, onClose})=> {

  return (
    <Dialog open={state} onClose={()=>{onClose(false)}} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className=''>
               <SchemeStatementHistory data={data}/>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={() => onClose(false)}
                className="inline-flex w-full justify-center rounded-md bg-sixth px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sixth focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sixth"
              >
                Go back
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default SummaryModalBox