import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../redux/alertSlice'
import api from '../../../utils/axiosInstance'

export const ModalBox = ({status, setStatus, custid, save})=> {

  const [PAN, setPAN] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    status:false,
    message:'Error in updaing PAN, please retry.'
  })

  const dispatch = useDispatch()

  const handleOnClose = ()=> {
    setStatus(false)
  }

  const handleOnChange = (value)=>{
    setError((pre)=>({...pre, status:false}))
    setPAN(value)
  }

  const updatePAN = async ()=>{
    try {
        setLoading(true)
        const response = await api.post('/scheme/update-pan',{custid:custid, pan:PAN})
        if (response.data?.success) {
            setPAN('');
            setLoading(false);
            handleOnClose();
            save();
        } else {
            setLoading(false)
            setError((pre)=>({...pre, status:true}))
        }
    } catch (error) {
        console.log('Error in generating scheme. ',error)
        dispatch(showAlert({type:'error', message:'Error in updating PAN.'}))
        setLoading(false)
    }
  }

  const handleSave = ()=>{
    if (PAN !== '' && PAN.length === 10) {
        updatePAN();
    } else {
        setError((pre)=>({...pre, status:true, message:'PAN must be a 10-character alphanumeric code.'}))
    }
  }



  return (
    <Dialog open={status} onClose={handleOnClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="font-bold font-mont text-gray-900">
                  Enter PAN
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm font-semibold text-gray-800 font-mont">
                  For schemes valued at ₹16,000 or above, a PAN is mandatory to proceed.
                  </p>
                </div>
              </div>
            </div>

            <div>
            <TextField
                    sx={{ width: '100%' }}
                    value={PAN}
                    label="PAN"
                    variant="standard"
                    onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleOnChange(e.target.value);
                        }
                      }}
                  />
            </div>
            {error.status && (<div className='text-red-500 font-mont font-semibold text-xs mt-1'>{error.message}</div>)}

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
                type="button"
                data-autofocus
                onClick={handleOnClose}
                disabled={loading}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold font-mont text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                CANCEL
              </button>
              <button
                type="button"
                onClick={handleSave}
                disabled={loading}
                className="inline-flex w-full justify-center rounded-md bg-third px-3 py-2 text-sm font-semibold font-mont text-white shadow-sm hover:bg-third/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-third/80 sm:col-start-2"
              >
                SAVE
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default ModalBox