import moment from "moment";
import React, { forwardRef } from "react";

const StatementPrint = forwardRef(
  ({ data, dfrom, dto, totalCredit, totalDebit, balance }, ref) => {
    return (
      <div 
      className="px-5 py-2"
      ref={ref}>

        <div className="">
          <div className="text-[45px] text-center">Jaina Jewellers</div>
          <div className="text-[18px] text-center">Address | Mobile number</div>
        </div>

        <div className="">
          <div className="my-4 font-semibold">
            Points ledger from {moment(dfrom).format('DD-MMM-YY')} to {moment(dto).format('DD-MMM-YY')}.
            </div>
        </div>

        <div className="w-full border-x border-gray-600">
          {/* Header */}
          <div className="flex border-y border-gray-600">
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 ">Date</div>
            <div className="w-[40%] font-bold border-r border-gray-600 px-2 ">Narration</div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 ">Sale Amt.</div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 ">Credit</div>
            <div className="w-[15%] font-bold px-2 ">Debit</div>
          </div>
          {/* Rows */}
          {data.map((row) => (
            <div className="flex border-b border-gray-600" key={row.MRN}>
              <div className="w-[15%] border-r border-gray-600 px-2 ">
                {row.INVDATE === null
                  ? moment(dfrom).format("DD-MMM-YY")
                  : moment(row.INVDATE).format("DD-MMM-YY")}
              </div>
              <div className="w-[40%] border-r border-gray-600 px-2 ">{row.NARRATION}</div>
              <div className="w-[15%] border-r border-gray-600 px-2  text-right">{row.SALEAMT}</div>
              <div className="w-[15%] border-r border-gray-600 px-2  text-right">{row.RCREDIT}</div>
              <div className="w-[15%] px-2  text-right">{row.RDEBIT}</div>
            </div>
          ))}
          {/*Total*/}
          <div className="flex border-b border-gray-600">
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 "></div>
            <div className="w-[40%] font-bold border-r border-gray-600 px-2 ">Total</div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 "></div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2  text-right">{totalCredit}</div>
            <div className="w-[15%] font-bold px-2  text-right">{totalDebit}</div>
          </div>
          {/*Balance*/}
          <div className="flex border-b border-gray-600">
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 "></div>
            <div className="w-[40%] font-bold border-r border-gray-600 px-2 ">Balance</div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2 "></div>
            <div className="w-[15%] font-bold border-r border-gray-600 px-2  text-right">{balance.credit}</div>
            <div className="w-[15%] font-bold px-2  text-right">{balance.debit}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default StatementPrint;
