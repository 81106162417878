import ImageUpload from "../customComponents/ImageUpload";
import ImageShow from "../customComponents/ImageShow";
import "../../constants/customScrollbar.css";
import SubHeader from "../customComponents/SubHeader";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/alertSlice";
import api from "../../utils/axiosInstance";
import { useEffect, useState } from "react";
import { updateImages } from "../../redux/imageSlice";
import Footer from "../customComponents/Footer";

const MyApp = () => {

  const [bannerImageArray, setBannerImageArray] = useState(null)
  const [cardImageArray, setCardImageArray] = useState(null)

  const dispatch = useDispatch()

   const handleGetImage = async(opt)=>{
    try {
      const response = await api.post('/file/get-files',{type:opt})
      if (response.data.success) {
        opt === 'banner' && setBannerImageArray(response.data.data) 
        opt === 'card' && setCardImageArray(response.data.data)  
        dispatch(updateImages(response.data.data.length))          // sending length to stop upload image more than 5
      } else {
        //dispatch(showAlert({type:'error', message:'Error in receiving image'}))
      }
    } catch (error) {
      //dispatch(showAlert({type:'error', message:'Error in receiving image'}))
      console.log(error)
    }
  }

  useEffect(()=>{
    handleGetImage('banner');
    handleGetImage('card');
  },[])

  return (
  <>
    <SubHeader text={"Customize Mobile App"} />
    <div className="mx-auto w-[1100px]">
       
       <div className="mt-8 shadow-custom-dark rounded-[10px]">
       <div className="flex items-center space-x-1 ml-6 pt-2 font-semibold font-mont text-primary">
        <ViewCarouselOutlinedIcon /> <p>Customize Banner</p>
      </div>
      <div className="flex space-x-20 px-6 bg-fourth/20 border-t mt-2">
        <div className="flex-shrink-0">
          <ImageUpload text={'Add to Banner'} type={'banner'} getImages={(opt)=>{handleGetImage(opt)}}/>
        </div>
        <div className="flex w-full px-4 bg-white space-x-4 my-6 rounded-[10px] py-6 overflow-x-auto shadow-custom-light">
          {bannerImageArray ? (
            bannerImageArray.map((item) => <ImageShow key={item.ID} src={item.URL} id={item.ID} path={item.PATH} type={item.TYPE} getImages={(opt)=>{handleGetImage(opt)}}/>)
          ) : (
            <p className="w-full my-auto text-primary/40 text-center font-mont font-semibold">
              No images found. Upload images for banner.
            </p>
          )}
        </div>
      </div>
       </div>

       <div className="mt-10 shadow-custom-dark rounded-[10px]">
       <div className="flex items-center space-x-1 ml-6 pt-2 font-semibold font-mont text-primary">
        <ViewCarouselOutlinedIcon /> <p>Customize e-catalogue</p>
      </div>
      <div className="flex space-x-20 px-6 bg-fourth/20 border-t mt-2">
        <div className="flex-shrink-0">
          <ImageUpload text={'Add to e-catalogue'} type={'card'} getImages={(opt)=>{handleGetImage(opt)}}/>
        </div>
        <div className="flex w-full px-4 bg-white space-x-4 my-6 rounded-[10px] py-6 overflow-x-auto shadow-custom-light">
          {cardImageArray ? (
            cardImageArray.map((item) => <ImageShow key={item.ID} src={item.URL} id={item.ID} path={item.PATH} type={item.TYPE} getImages={(opt)=>{handleGetImage(opt)}}/>)
          ) : (
            <p className="w-full my-auto text-primary/40 text-center font-mont font-semibold">
              No images found. Upload images for e-catalogue.
            </p>
          )}
        </div>
      </div>
       </div>

    </div>
    </>
  );
};

export default MyApp;
