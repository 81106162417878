import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/axiosInstance";
import { showAlert } from "../../redux/alertSlice";
import SubHeader from "../customComponents/SubHeader";

const Profile = () => {
  const [formData, setFormData] = useState({
    username: "",
    oldpassword: "",
    newpassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });

  const dispatch = useDispatch();
  const user = useSelector((store) => store?.user?.activeUser);
  console.log(user);

  const handleOnChange = (name, value) => {
    setError((preValue) => ({ ...preValue, status: false, message: "" }));
    setFormData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData((preValue) => ({
      ...preValue,
      username: user,
      oldpassword: "",
      newpassword: "",
    }));
  };

  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      const response = await api.post("/user/update-current-user", {
        username: user,
        oldpassword: formData.oldpassword,
        newpassword: formData.newpassword,
      });
      if (response.data.success) {
        dispatch(
          showAlert({ type: "success", message: response?.data?.message })
        );
        handleCancel();
        setLoading(false);
      } else {
        setError((preValue) => ({
          ...preValue,
          status: true,
          message: response?.data?.message,
        }));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showAlert({ type: "warning", message: "Error in updating user." })
      );
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (formData.newpassword === "" || formData.oldpassword === "") {
      formData.newpassword === "" &&
        dispatch(
          showAlert({
            type: "warning",
            message: "Please provide new password.",
          })
        );
      formData.oldpassword === "" &&
        dispatch(
          showAlert({
            type: "warning",
            message: "Please provide old password.",
          })
        );
    } else {
      handleUpdateUser();
    }
  };

  return (
    <div>
      <SubHeader text={"My Profile"} />

      <div className="overflow-hidden lg:mt-8 lg:max-w-[40rem] lg:mx-auto bg-white shadow-custom-dark rounded-lg">
        <div className="bg-primary text-white font-semibold py-2 px-4">Update Profile</div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-200">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm my-auto font-semibold font-mont text-black">
                Username
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <input
                  defaultValue={user}
                  disabled={true}
                  maxLength={30}
                  className="w-full lg:w-80 mt-1 rounded-md border border-gray-300 py-1.5 px-3 text-black font-bold shadow-md font-mont text-sm outline-none focus:border-2 focus:border-blue-600/90"
                />
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm my-auto font-mont font-semibold text-black">
                Old Password
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <input
                  value={formData.oldpassword}
                  maxLength={15}
                  placeholder="Enter current password"
                  onChange={(e) => {
                    handleOnChange("oldpassword", e.target.value);
                  }}
                  className="w-full lg:w-80 mt-1 rounded-md border border-gray-300 py-1.5 px-3 text-black font-bold shadow-md font-mont text-sm outline-none focus:border-2 focus:border-blue-600/90"
                />
                {error.status && (
                  <div className="mt-1 text-xs text-red-500 pl-1 font-semibold font-mono">
                    {error?.message}
                  </div>
                )}
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm my-auto font-mont font-semibold text-black">
                New Password
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <input
                  value={formData.newpassword}
                  maxLength={15}
                  placeholder="Enter new password"
                  onChange={(e) => {
                    handleOnChange("newpassword", e.target.value);
                  }}
                  className="w-full lg:w-80 mt-1 rounded-md border border-gray-300 py-1.5 px-3 text-black font-bold shadow-md font-mont text-sm outline-none focus:border-2 focus:border-blue-600/90"
                />
              </dd>
            </div>

            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm my-auto font-mont font-semibold text-black"></dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <button
                  onClick={handleSave}
                  className="py-2 px-8 mr-2 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70"
                  disabled={loading}
                >
                  UPDATE
                </button>
                <button
                  onClick={handleCancel}
                  className="py-2 px-8 border border-fifth  text-fifth text-sm font-mont font-semibold rounded-[5px] hover:bg-white"
                  disabled={loading}
                >
                  CANCEL
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Profile;
