import moment from 'moment'
import React from 'react'

const SchemeStatementHistory = ({data}) => {

    const total = data.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div className="border h-full rounded-[15px] shadow-custom-light overflow-hidden">
      <div
        className={`bg-sixth flex text-white font-mont font-semibold text-sm`}
      >
        <div className="w-[8%] py-2 px-3">S no.</div>
        <div className="w-[25%] py-2 px-3">Installment Date</div>
        <div className="w-[42%] py-2 px-3">Narration</div>
        <div className="w-[25%] py-2 px-3">Amount</div>
      </div>
      <div className="h-[300px] overflow-y-auto font-mont font-bold text-sm">
        {data.length > 0 ? (
          data.map((row, i) => (
            <div
              key={i}
              className={`flex font-semibold text-sm hover:bg-sixth/30 ${
                i % 2 === 0 ? "bg-sixth/10" : "bg-sixth/5"
              }`}
            > 
              <div className="w-[8%] h-[35px] flex items-center px-3">
                {i+1}
              </div>
              <div className="w-[25%] h-[35px] flex items-center px-3">
                {moment(row?.date).format('DD-MM-YYYY')}
              </div>
              <div className="w-[42%] h-[35px] flex items-center px-3">
               {row?.narration}
              </div>
              <div className="w-[25%] h-[35px] flex items-center justify-end px-3">
              ₹ {row?.amount}
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <img className="w-64 mx-auto" src="/table/tablebg.jpg" alt="" />
            <div className="text-center font-mont tedt-sm font-semibold text-gray-400">
                No results yet.
            </div>
          </div>
        )}
      </div>
      <div className='h-[50px] flex items-center border-t border-gray-300'>
        <div className='w-[8%] px-3'></div>
        <div className='w-[25%] px-3'>              
            <button
                //onClick={reactToPrintFn}
                className="py-1 w-32 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
              >
                Print
            </button>
        </div>
        <div className='w-[42%] px-3'>              
            <button
                //onClick={reactToPrintFn}
                className="py-1 w-32 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
              >
                Download CSV
            </button>
        </div>
        <div className='w-[25%] px-3 text-right text-sm font-mont font-bold'>              
           Total - ₹ {total}
        </div>
      </div>
    </div>
  )
}

export default SchemeStatementHistory
